import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import VueCookies from 'vue-cookies'
import apiClient from '@/services/axios'
import UserStore from '@/store/user'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  // mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: 'statistics',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // handBooks
        {
          path: '/handbook/region',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/city',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/district',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/category',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/station',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/realty_type',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/operation_type',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/life_type',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/type_wall',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/heating',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/layout',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/currency',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/floor',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        {
          path: '/handbook/apartment',
          meta: {
            title: 'Справочники',
          },
          component: () => import('./views/handbook/universal'),
        },
        // Content
        {
          path: '/content/products',
          meta: {
            title: 'Объявления',
          },
          component: () => import('./views/content/products'),
        },
        {
          path: '/content/header',
          meta: {
            title: 'Шапка',
          },
          component: () => import('./views/content/header'),
        },
        {
          path: '/content/footer',
          meta: {
            title: 'Подвал',
          },
          component: () => import('./views/content/footer'),
        },
        {
          path: '/content/pages',
          meta: {
            title: 'Контентные страницы',
          },
          component: () => import('./views/content/pages'),
        },
        {
          path: '/content/pages/:id',
          meta: {
            title: 'Страница',
          },
          component: () => import('./views/content/pages/id'),
        },
        {
          path: '/content/pages/preview/:id',
          meta: {
            title: 'Страница',
          },
          component: () => import('./views/content/pages/preview/id'),
        },
        {
          path: '/content/banners',
          meta: {
            title: 'Банера',
          },
          component: () => import('./views/content/banners'),
        },
        {
          path: '/content/banners/:id',
          meta: {
            title: 'Страница',
          },
          component: () => import('./views/content/banners/id'),
        },
        {
          path: '/content/news',
          meta: {
            title: 'Новости',
          },
          component: () => import('./views/content/news'),
        },
        {
          path: '/content/news/:id',
          meta: {
            title: 'Новости',
          },
          component: () => import('./views/content/news/id'),
        },
        {
          path: '/content/news/news_tags/list',
          meta: {
            title: 'Новости',
          },
          component: () => import('./views/content/news/news_tags/'),
        },
        {
          path: '/content/contacts',
          meta: {
            title: 'Форма контактов',
          },
          component: () => import('./views/content/contacts'),
        },
        {
          path: '/content/main_settings',
          meta: {
            title: 'Настройки сайта',
          },
          component: () => import('./views/content/settings/main_settings'),
        },
        {
          path: '/content/all_pages',
          meta: {
            title: 'Страницы сайта',
          },
          component: () => import('./views/content/all_pages/'),
        },
        {
          path: '/content/all_pages/main',
          meta: {
            title: 'Главная',
          },
          component: () => import('./views/content/all_pages/main'),
        },
        {
          path: '/content/all_pages/check',
          meta: {
            title: 'Главная',
          },
          component: () => import('./views/content/all_pages/check'),
        },
        {
          path: '/content/all_pages/about_us',
          meta: {
            title: 'Главная',
          },
          component: () => import('./views/content/all_pages/about_us'),
        },
        {
          path: '/content/all_pages/catalog',
          meta: {
            title: 'Каталог',
          },
          component: () => import('./views/content/all_pages/catalog'),
        },
        {
          path: '/content/all_pages/auto',
          meta: {
            title: 'Карточка авто',
          },
          component: () => import('./views/content/all_pages/auto'),
        },
        {
          path: '/content/image_settings',
          meta: {
            title: 'Настройка изображений',
          },
          component: () => import('./views/content/settings/image_settings'),
        },
        // Users
        {
          path: '/users/clients',
          meta: {
            title: 'Пользователи',
          },
          component: () => import('./views/users/clients/'),
        },
        {
          path: '/users/admins/',
          meta: {
            title: 'Администраторы',
          },
          component: () => import('./views/users/admins/'),
          beforeEnter(to, from, next) {
            routeMiddleware(['super_admin', 'admin'], next)
          },
        },
        {
          path: '/users/clients/:id',
          meta: {
            title: 'Пользователь',
          },
          component: () => import('./views/users/clients/id'),
        },
        {
          path: '/users/admins/:id',
          meta: {
            title: 'Администратор',
          },
          component: () => import('./views/users/admins/id'),
          beforeEnter(to, from, next) {
            routeMiddleware(['super_admin', 'admin'], next)
          },
        },
        {
          path: '/profile',
          meta: {
            title: 'Профиль',
          },
          component: () => import('./views/profile'),
        },
        // Statistics
        {
          path: '/statistics',
          meta: {
            title: 'Статистика',
          },
          component: () => import('./views/statistics/'),
        },
        // Functional
        {
          path: '/functional/complaints',
          meta: {
            title: 'Жалобы',
          },
          component: () => import('./views/functional/complaints/'),
        },
        {
          path: '/functional/complaints/:id',
          meta: {
            title: 'Жалобы',
          },
          component: () => import('./views/functional/complaints/id'),
        },
        {
          path: '/functional/usability',
          meta: {
            title: 'Отзывы фильтра',
          },
          component: () => import('./views/functional/usability/'),
        },
        {
          path: '/functional/usability/:id',
          meta: {
            title: 'Отзывы фильтра',
          },
          component: () => import('./views/functional/usability/id'),
        },
        {
          path: '/functional/mailings/create',
          meta: {
            title: 'Рассылки - создать рассылку',
          },
          component: () => import('./views/functional/mailings/create'),
        },
        {
          path: '/functional/mailings/edit/:id',
          meta: {
            title: 'Рассылки - редактировать рассылку',
          },
          component: () => import('./views/functional/mailings/edit'),
        },
        {
          path: '/functional/mailings/:id',
          meta: {
            title: 'Рассылки',
          },
          component: () => import('./views/functional/mailings/id'),
        },
        {
          path: '/functional/mailings',
          meta: {
            title: 'Рассылки',
          },
          component: () => import('./views/functional/mailings/index'),
        },
        {
          path: '/functional/publications',
          meta: {
            title: 'Тарифы публикаций',
          },
          component: () => import('./views/functional/publications'),
        },
        {
          path: '/functional/promotions',
          meta: {
            title: 'Тарифы продвижения',
          },
          component: () => import('./views/functional/promotions'),
        },
        /* {
          path: '/functional/checks',
          meta: {
            title: 'Проверки авто',
          },
          component: () => import('./views/functional/checks'),
        }, */
        /* {
          path: '/functional/subscriptions',
          meta: {
            title: 'Подписки',
          },
          component: () => import('./views/functional/subscriptions'),
        }, */
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },

    // Redirect to 404
    {
      path: '*', redirect: 'auth/404', hidden: true,
    },
  ],
})

let role

router.beforeEach(async (to, from, next) => {
  if (!VueCookies.get('token') && to.path !== '/auth/login') {
    await router.push({ path: '/auth/login' })
  } else if (VueCookies.get('token')) {
    role = UserStore.state.user ? UserStore.state.user.role : ''

    if (!role) {
      const user = (await apiClient.get('/admin/my_profile')).data.data.value
      role = user.role

      await store.dispatch('user/LOAD_CURRENT_ACCOUNT', user)
    }

    next()
  } else {
    next()
  }
})

const routeMiddleware = (allowedRoleList, next) => {
  if (allowedRoleList.includes(role)) {
    next()
  } else {
    router.back()
  }
}

export default router
