export const getMenuData = [
  {
    title: 'Статистика',
    key: 'Статистика',
    icon: 'fe fe-activity',
    url: '/statistics',
  },

  {
    title: 'Справочники',
    key: 'Справочники',
    icon: 'fe fe-sliders',
    // roles: ['admin'], // set user roles with access to this route
    count: 13,
    children: [
      {
        title: 'Регионы',
        key: 'Регионы',
        url: '/handbook/region',
      },
      {
        title: 'Города',
        key: 'Города',
        url: '/handbook/city',
      },
      {
        title: 'Районы',
        key: 'Районы',
        url: '/handbook/district',
      },
      {
        title: 'Категории',
        key: 'Категории',
        url: '/handbook/category',
      },
      {
        title: 'Станции',
        key: 'Станции',
        url: '/handbook/station',
      },
      {
        title: 'Тип недвижимости',
        key: 'Тип недвижимости',
        url: '/handbook/realty_type',
      },
      {
        title: 'Тип операций',
        key: 'Тип операций',
        url: '/handbook/operation_type',
      },
      {
        title: 'Тип жилья',
        key: 'Тип жилья',
        url: '/handbook/life_type',
      },
      {
        title: 'Тип стен',
        key: 'Тип стен',
        url: '/handbook/type_wall',
      },
      {
        title: 'Тип отопления',
        key: 'Тип отопления',
        url: '/handbook/heating',
      },
      {
        title: 'Планировка',
        key: 'Планировка',
        url: '/handbook/layout',
      },
      {
        title: 'Валюта',
        key: 'Валюта',
        url: '/handbook/currency',
      },
      {
        title: 'Этаж',
        key: 'Этаж',
        url: '/handbook/floor',
      },
      {
        title: 'Количесво комнат',
        key: 'Количесво комнат',
        url: '/handbook/apartment',
      },
    ],
  },

  {
    title: 'Настройка',
    key: 'Настройка',
    icon: 'fe fe-settings',
    // roles: ['admin'], // set user roles with access to this route
    count: 7,
    children: [
      {
        title: 'Шапка',
        key: 'Шапка',
        url: '/content/header',
      },
      {
        title: 'Подвал',
        key: 'Подвал',
        url: '/content/footer',
      },
      {
        title: 'Банера',
        key: 'Банера',
        url: '/content/banners',
      },
      {
        title: 'Настройка сайта',
        key: 'Настройка сайта',
        url: '/content/main_settings',
      },
      {
        title: 'Настройка изображений',
        key: 'Настройка изображений',
        url: '/content/image_settings',
      },
      {
        title: 'Тарифы публикаций',
        key: 'Тарифы публикаций',
        url: '/functional/publications',
      },
      {
        title: 'Тарифы продвижения',
        key: 'Тарифы продвижения',
        url: '/functional/promotions',
      },
    ],
  },

  {
    title: 'Контент',
    key: 'Контент',
    icon: 'fe fe-align-center',
    // roles: ['admin'], // set user roles with access to this route
    count: 3,
    children: [
      {
        title: 'Новости',
        key: 'Новости',
        url: '/content/news',
      },
      {
        title: 'Страницы сайта',
        key: 'Страницы сайта',
        url: '/content/all_pages',
      },
      {
        title: 'Контентные страницы',
        key: 'Контентные страницы',
        url: '/content/pages',
      },
    ],
  },

  {
    title: 'Пользователи',
    key: 'Пользователи',
    icon: 'fe fe-users',
    // roles: ['admin'], // set user roles with access to this route
    count: 2,
    children: [
      {
        title: 'Клиенты',
        key: 'Клиенты',
        url: '/users/clients',
      },
      {
        title: 'Администраторы',
        key: 'Администраторы',
        url: '/users/admins',
        condition: ['super_admin', 'admin'],
      },
    ],
  },

  {
    title: 'Функционал',
    key: 'Функционал',
    icon: 'fe fe-cpu',
    // roles: ['admin'], // set user roles with access to this route
    count: 4,
    children: [
      {
        title: 'Жалобы',
        key: 'Жалобы',
        url: '/functional/complaints',
      },
      {
        title: 'Отзывы фильтра',
        key: 'Отзывы фильтра',
        url: '/functional/usability',
      },
      {
        title: 'Рассылки',
        key: 'Рассылки',
        url: '/functional/mailings',
      },
      /* {
        title: 'Проверки авто',
        key: 'Проверки авто',
        url: '/functional/checks',
      }, */
      /* {
        title: 'Подписки',
        key: 'Подписки',
        url: '/functional/subscriptions',
      }, */
      {
        title: 'Объявления',
        key: 'Объявления',
        url: '/content/products',
      },
    ],
  },
]
